<template>
  <div class="answer-type-selector">
    <div class="form-group">
      <h5 class="uk-text-bold mb-2">Cevap Türü</h5>
      <div class="radio-group d-flex">
        <div class="form-check mr-3">
          <input
              class="form-check-input"
              type="radio"
              :value="'file'"
              :checked="isAnswerTypeMission === 'file'"
              id="answer_type_1"
              @change="onAnswerTypeChange('file')"
          />
          <label class="form-check-label" for="answer_type_1">Dosya Yükleme</label>
        </div>
        <div class="form-check mr-3">
          <input
              id="answer_type_2"
              class="form-check-input"
              type="radio"
              :value="'text'"
              :checked="isAnswerTypeMission === 'text'"
              @change="onAnswerTypeChange('text')"
          />
          <label class="form-check-label" for="answer_type_2">Metin Cevabı</label>
        </div>
        <div class="form-check">
          <input
              class="form-check-input"
              type="checkbox"
              :checked="completion_rule === 'admin_approved'"
              id="complete_type_3"
              @click="toggleAdminApproval"
          />
          <label class="form-check-label" for="complete_type_3">Admin Onayladığında</label>
        </div>
      </div>
    </div>

    <div class="form-group">
      <h5 class="uk-text-bold mb-2">Tamamlanma Kuralı</h5>
      <div class="radio-group">
        <div class="form-check mb-1">
          <input
              class="form-check-input"
              type="radio"
              value="answered"
              name="complete_type"
              id="complete_type_1"
              :checked="completion_rule === 'answered'"
              disabled
          />
          <label class="form-check-label" for="complete_type_1">Dosya Yüklendiğinde</label>
        </div>

        <div class="form-check mb-1">
          <input
              class="form-check-input"
              type="radio"
              value="correct_answer_given"
              name="complete_type"
              id="complete_type_2"
              :checked="completion_rule === 'correct_answer_given'"
              disabled
          />
          <label class="form-check-label" for="complete_type_2">Doğru Cevap Verildiğinde</label>

          <div class="row mt-2" v-if="completion_rule === 'correct_answer_given'">
            <div class="col-md-3">
              <label class="mb-0 mt-2" for="correct_answer">Doğru Cevap:</label>
            </div>
            <div class="col-md-9">
              <input
                  name="correct_answer"
                  id="correct_answer"
                  type="text"
                  :value="accepted_right_answer"
                  @keyup="setAnswer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isAnswerTypeMission: String,
    completion_rule: {
      type:String,
      default:'answered'
    },
    accepted_right_answer: String,
  },
  methods: {
    //dosya yukleme seçildiğinde => tamamlama kuralı = dosya yuklendiğinde otomatik seçiliyor
    //metin cevabı seçildiğinde => tamamlama kuralı = doğru cevap verildiğinde otomatik seçiliyor
    //admin onaylandığında , metin cevabı veya dosya yuklemeden biri seçilebilir
    //ayrıca metin cevabı için açılan inputta daha önceden girilmiş bir veri varsa silinir

    // cevap turleri => Dosya yükleme = answered, metin cevabı = correct_answer_given
    // tamamlama kuralları  => admin onayladığında = admin_approved , Dosya Yüklendiğinde =file ,Doğru Cevap Verildiğinde = text

    toggleAdminApproval() {
      if (this.completion_rule === 'admin_approved') {
        this.$emit("update:completion_rule", null); // Admin seçimini kaldır

        if (this.isAnswerTypeMission === 'text') {
          this.$emit("update:completion_rule", 'correct_answer_given');
        } else {
          this.$emit("update:completion_rule", 'answered');
        }
      } else {
        this.$emit("update:completion_rule", 'admin_approved');
        this.$emit("update:accepted_right_answer", null);
      }
    },

    onAnswerTypeChange(value) {
      this.$emit("update:answerType", value);

      if (this.completion_rule === 'admin_approved') {
        this.$emit("update:accepted_right_answer", null);
        return;
      }
      if (value === 'file') {
        this.$emit("update:accepted_right_answer", null);
        this.$emit("update:completion_rule", 'answered');
      } else if (value === 'text') {
        this.$emit("update:completion_rule", 'correct_answer_given');
      }
    },

    setAnswer(e){
      console.log("va",e)
      this.$emit("update:accepted_right_answer", e.target.value);
    }
  }
};
</script>
